<template>
  <div>
    <div class="home-bg1">
      <div class="content-a wr">
        <div class="w1520 f-cb">
          <div class="left">
            <div class="tit hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">
              物联网中台 (IoT Middleware)
              <div class="title_divider" style="background: rgb(0, 59, 179);"></div>
            </div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;font-size: 18px;color:#fff;">
                <span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">
                  <!-- 迪倍特设备运维管理云平台（迪倍特巡检云）是集设备运维管理、数据储存、数据处理为一体的云计算平台，该平台通过对设备的巡检、点检、维修以及运行状态等数据信息进行收集、传输、储存、统计、分析、预测和维护，
                  来提高设备工作效率和维护维修效率，系统自动生成相关任务、报表及设备运行报告，迪倍特以建设信息化设施设备运维系统，保障高效化安全生产体系目标，融合标准化、流程化、协作化、数字化、精细化管理理念，为企
                  业打造全员参与的高校协同的设备运维管理SAAS平台。 -->
                  物联网中台作为连接物理设备与数字化服务之间的桥梁，是现代物联网架构的核心组成部分。它通过集中管理设备、数据、应用和服务，提供了一个高效、可扩展的框架，能够支持大规模设备的接入与管理。物联网中台不仅能够实
                  现设备数据的实时采集、存储、分析与展示，还具备设备的远程控制、故障诊断与预警等功能。它的核心优势在于其强大的数据处理能力、实时性和跨平台兼容性，能够有效提升系统的可靠性和灵活性。同时，物联网中台能够支持
                  多种通信协议和标准，实现各种设备和系统之间的无缝对接，助力数字化转型的推进。
                </span>
              </p><p><br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-b wr">
        <div class="w1520">
          <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">主要功能</div>
          <div class="list">
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">设备注册与管理</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备的添加、删除、更新</span></p><p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备分组与分类</span></p><p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备状态监控（在线/离线）</span></p></div>
              </div>
            </div>
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">远程控制与配置</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">远程开关控制</span></p>
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">参数配置与调整</span></p>
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">OTA（Over-the-Air）固件升级</span></p>
                </div>
              </div>
            </div>
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">数据采集与监测</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">实时数据采集（如温度、湿度、电压等</span></p>
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">历史数据存储与分析</span></p>
                  <p style="text-align: justify; line-height: 28px;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">设备异常警报（如温度过高、断网等）</span></p>
                </div>
              </div>
            </div>
            <div class="li wow fadeInUp animated animated animated" style="visibility: visible;" v-for="(item,index) in functions" :key="index">
              <div class="txt">
                <div class="tit-32 mc lh1 hnb">{{item.subheading}}</div>
                <div class="tit-18">
                  <p style="text-align: justify; line-height: 28px;" v-for="(it,i) in item.content" :key="i"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">{{it}}</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="content-b wr">
        <div class="w1520">
          <div class="left wow fadeInUp animated animated animated" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s;">
            <img src="../../img/fuwuBg.jpg" alt="总体概况">
          </div>
          <div class="right">
            <div class="tit-50 hnm lh1 wow fadeInUp animated animated animated" style="visibility: visible;">总体概况</div>
            <div class="tit-18 hnr t2 wow fadeInUp animated animated animated" data-wow-delay=".3s" style="visibility: visible; animation-delay: 0.3s;">
              <p style="line-height: 31.5px; text-align: justify;"><span style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;;">迪倍特设备运维管理云平台（迪倍特巡检云）是集设备运维管理、数据储存、数据处理为一体的云计算平台，该平台通过对设备的巡检、点检、维修以及运行状态等数据信息进行收集、传输、储存、统计、分析、预测和维护，来提高设备工作效率和维护维修效率，系统自动生成相关任务、报表及设备运行报告，迪倍特以建设信息化设施设备运维系统，保障高效化安全生产体系目标，融合标准化、流程化、协作化、数字化、精细化管理理念，为企业打造全员参与的高校协同的设备运维管理SAAS平台。</span></p><p><br></p>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
          functions: [
            {
              subheading: '安全管理',
              content: [
                '设备身份认证（如基于MQTT或TLS加密）',
                '访问权限管理',
                '数据加密传输',
              ]
            },
            {
              subheading: '日志与报告',
              content: [
                '操作日志记录',
                '设备运行状态报告',
                '故障诊断与维护建议',
              ]
            },
          ],
        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
  user-select: none;
  width: 100%;
  position: relative;
  overflow: auto;
  .content-a {
    background-image: url('../../img/coreTechnology1.jpg');
    background-size: cover;
    .w1520 {
      padding: 120px 0 16px;
      border-bottom: 1px solid #d5d6d6;
    }
    .left {
      width: 73%;
      float: left;
      .tit {
        font-weight: 500;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 34px;
       .title_divider {
          width: 40px;
          height: 6px;
          margin-top: 14px;
        } 
      }
      .describe {
        color: #fff;
        font-size: 20px;
        width: 80%;
        overflow: hidden;
        .txt { 
          margin-bottom: 10px;
          text-align: justify;
        }
      }
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
    .right {
      width: 50%;
      padding-left: 126px;
      float: left;
    }
  }
  .content-b {
    padding: 60px 0;
    .list {
      margin-top: 49px;
      .li {
        width: calc(30%);
        height: 244px;
        border-radius: 5px;
        box-shadow: -1px 0px 12px rgba(0, 0, 0, 0.1);
        float: left;
        margin-right: 40px;
        display: flex;
        padding: 40px 20px;
        margin-bottom: 50px;
        transition: all 0.5s;
        padding-right: 40px;
        .txt {
          // width: calc(40%);
          padding-left: 12px;
          .tit-18 {
            font-size: 0;
            text-align: left;
            margin-top: 20px;
            margin-left: 20px;
            p {
              // display: inline-block;
              // width: 50%;
              font-size: 16px;
              line-height: 2;
              color: #666666;
              padding-left: 18px;
              transition: all 0.5s;
              position: relative;
            }
            p::after {
              content: "";
              position: absolute;
              width: 7px;
              height: 7px;
              background-color: #666;
              opacity: 0.5;
              border-radius: 50%;
              top: 10px;
              left: 0;
            }
          }
        }
      }
    }
  }
  .content-c {
    padding: 60px 0;
    .left {
      width: 50%;
      padding-right: 126px;
      float: left;
    }
    .right {
      width: 50%;
      float: left;
      .tit-50 {
        line-height: 1;
        font-size: 50px;
      }
      .t2 {
        margin-top: 1.25rem;
        line-height: 2;
      }
    }
  }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        // margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>